import axios from 'axios';
import convert from 'xml-js';

export const fetchProgramList = async () => {
	try {
		// const { data } = await axios.get('http://213.184.238.35:8888/cinema_data.xml');
		const { data } = await axios.get('get.php');
		const parsedXml = convert.xml2json(data);
		const parsedPrograms = JSON.parse(parsedXml);
		const programs = parsedPrograms.elements[0].elements;
		return programs;
	} catch (e) {
		console.log(e);
		return [];
	}
};
