<template>
	<section id="program">
		<div class="container">
			<div class="row">
				<h2 class="section-heading col-12">Телепрограмма</h2>
				<div class="picker col-12">
					<div class="pickers-wrapper">
						<day-picker @changeSelectedDay="changeSelectedDay" :selectedDay="selectedDay" />

						<week-picker
							v-if="
								computedFirstMonday &&
									computedFirstSunday &&
									computedSecondMonday &&
									computedSecondSunday
							"
							@changeActiveWeek="changeActiveWeek"
							:activeWeek="computedActiveWeek"
							:selectedDay="selectedDay"
							:firstMonday="computedFirstMonday"
							:firstSunday="computedFirstSunday"
							:secondMonday="computedSecondMonday"
							:secondSunday="computedSecondSunday"
						/>
					</div>
				</div>

				<checked-date
					v-if="weeks.firstWeekMondayTimestamp"
					:activeWeek="computedActiveWeek"
					:selectedDay="selectedDay"
					:firstWeekMondayTimestamp="weeks.firstWeekMondayTimestamp"
				/>

				<template v-for="period in programList">
					<program :period="period" :key="period.title" />
				</template>
			</div>
		</div>
	</section>
</template>

<script>
import moment from 'moment';
import { fetchProgramList } from './services';

const dayPicker = () => import('./components/pickers/dayPicker');
const weekPicker = () => import('./components/pickers/weekPicker');
const checkedDate = () => import('./components/checkedDate');
const program = () => import('./components/program/program');

export default {
	components: {
		dayPicker,
		weekPicker,
		checkedDate,
		program,
	},
	data: () => ({
		selectedDay: `${new Date(Date.now()).getDay()}`,
		parsedPrograms: {
			// stores program lists according to program week and weekday
			week_1: {
				'1': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'2': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'3': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'4': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'5': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'6': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'0': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
			},
			week_2: {
				'1': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'2': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'3': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'4': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'5': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'6': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
				'0': {
					night: {
						title: 'Ночь',
						programList: [],
					},
					morning: {
						title: 'Утро',
						programList: [],
					},
					day: {
						title: 'День',
						programList: [],
					},
					evening: {
						title: 'Вечер',
						programList: [],
					},
				},
			},
		},
		activeWeek: '1',
		weeks: {
			firstWeekMondayTimestamp: null,
			first: {
				monday: null,
				sunday: null,
			},
			second: {
				monday: null,
				sunday: null,
			},
		},
		dayList: [
			{ title: 'Понедельник', value: '1' },
			{ title: 'Вторник', value: '2' },
			{ title: 'Среда', value: '3' },
			{ title: 'Четверг', value: '4' },
			{ title: 'Пятница', value: '5' },
			{ title: 'Суббота', value: '6' },
			{ title: 'Воскресенье', value: '0' },
		],
	}),
	computed: {
		// returns selected week and monday and sunday of it
		computedActiveWeek() {
			return this.activeWeek;
		},
		computedFirstMonday() {
			return this.weeks.first.monday;
		},
		computedFirstSunday() {
			return this.weeks.first.sunday;
		},
		computedSecondMonday() {
			return this.weeks.second.monday;
		},
		computedSecondSunday() {
			return this.weeks.second.sunday;
		},
		programList() {
			const vm = this;
			// returns program list according to selected week and weekday
			return vm.parsedPrograms[`week_${vm.computedActiveWeek}`][vm.selectedDay];
		},
	},
	methods: {
		changeSelectedDay(value) {
			this.selectedDay = value;
		},
		getWeeksPeriod() {
			// get monday and sunday of this and next week
			const date = new Date(Date.now()),
				day = date.getDay(),
				diff = date.getDate() - day + (day == 0 ? -6 : 1), // 0 - sunday
				firstWeekMondayTimestamp = Date.parse(new Date(date.setDate(diff))),
				days = 1000 * 60 * 60 * 24,
				firstWeekMonday = new Date(firstWeekMondayTimestamp).toLocaleDateString(),
				firstWeekSunday = new Date(firstWeekMondayTimestamp + 6 * days).toLocaleDateString(),
				secondWeekMonday = new Date(firstWeekMondayTimestamp + 7 * days).toLocaleDateString(),
				secondWeekSunday = new Date(firstWeekMondayTimestamp + 13 * days).toLocaleDateString();

			this.weeks.firstWeekMondayTimestamp = firstWeekMondayTimestamp;
			this.weeks.first.monday = firstWeekMonday;
			this.weeks.first.sunday = firstWeekSunday;
			this.weeks.second.monday = secondWeekMonday;
			this.weeks.second.sunday = secondWeekSunday;
		},

		changeActiveWeek({ selectedDay, activeWeek }) {
			this.selectedDay = selectedDay;
			this.activeWeek = activeWeek;
		},

		padNumber(num) {
			// returns stringified number less than 10 as '02'
			return num < 10 ? `0${num}` : `${num}`;
		},
		getTimePeriod(hour) {
			// helps to distribute programs according their start hours
			if (hour >= 0 && hour < 6) {
				return 'night';
			} else if (hour >= 6 && hour < 12) {
				return 'morning';
			} else if (hour >= 12 && hour < 18) {
				return 'day';
			} else if (hour >= 18 && hour <= 23) {
				return 'evening';
			}
		},
		async fetchPrograms() {
			const vm = this;
			// fetches programs and stores they to data
			const programs = await fetchProgramList();
			// associates date with appropriate array
			programs.length > 0 &&
				programs.forEach(program => {
					if (program?.attributes?.start && program?.attributes?.stop) {
						const unparsedDateStart = program.attributes.start.split(' ')[0],
							unparsedDateStringStart = moment(unparsedDateStart, 'YYYY-MM-DD hh:mm:ss'),
							dateStringStart = new Date(unparsedDateStringStart),
							day = dateStringStart.getDay() + '',
							hour = dateStringStart.getHours(),
							minute = dateStringStart.getMinutes(),
							second = dateStringStart.getSeconds(),
							timePeriod = vm.getTimePeriod(hour),
							// timestamps are used to find active program
							// timestamp of program start
							timestampStart = Date.parse(dateStringStart),
							// timestamp of program end
							unparsedDateStop = program.attributes.stop.split(' ')[0],
							unparsedDateStringStop = moment(unparsedDateStop, 'YYYY-MM-DD hh:mm:ss'),
							dateStringStop = new Date(unparsedDateStringStop),
							timestampStop = Date.parse(dateStringStop),
							secondWeekMondayTimestamp = Date.parse(
								vm.computedSecondMonday
									.split('.')
									.reverse()
									.join('/')
							),
							week = timestampStart < secondWeekMondayTimestamp ? '1' : '2',
							programItem = {
								day,
								hour: vm.padNumber(hour),
								minute: vm.padNumber(minute),
								second: vm.padNumber(second),
								start: program.attributes.start,
								stop: program.attributes.stop,
								title: program.elements[0].elements[0].text,
								description: program.elements[2] && program.elements[2].elements[0].text,
								week: timestampStart < secondWeekMondayTimestamp ? '1' : '2',
								isActive: Date.now() >= timestampStart && Date.now() <= timestampStop,
								isShown: Date.now() > timestampStop,
							};

						vm.parsedPrograms[`week_${week}`][day][timePeriod].programList.every(
							({ start }) => start !== program.attributes.start
						) && vm.parsedPrograms[`week_${week}`][day][timePeriod].programList.push(programItem);
						vm.parsedPrograms[`week_${week}`][day][timePeriod].programList.sort((a, b) => {
							return a.hour !== b.hour ? a.hour - b.hour : a.minute - b.minute;
						});
					}
				});
		},
	},
	async mounted() {
		await this.getWeeksPeriod();
		await this.fetchPrograms();

		// sets fetching programs with 1-minute interval
		setInterval(await this.fetchPrograms, 60000);
	},
	async beforeDestroy() {
		// deletes this interval
		clearInterval(await this.fetchPrograms);
	},
};
</script>
